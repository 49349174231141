import { ReportService } from '@/app/shared/services/report.service';
import { Component } from '@angular/core';
import { Report, TableauTrustedUrlResponseDto } from '@/app/shared/interface/report.interface';

@Component({
  selector: 'app-report',
  templateUrl: './report.component.html',
  styleUrl: './report.component.scss'
})
export class ReportComponent {

  headerColumns = [
    "Tên báo cáo",
    "Chi tiết",
  ];

  reports: Report[] = [];

  constructor(
    private reportService: ReportService
  ) {}

  ngOnInit() {
    this.loadReports();
  }

  loadReports() {
    this.reportService.getUserTableauServices().subscribe({
      next: (res: Report[]) => {
        if (res) {
          this.reports = res;
        }
      },
      error: (error: any) => {
        console.log(error);
      }
    })
  }

  openReport(report: Report) {
    this.reportService.getTrustedUrlSupplier(report.reportName).subscribe({
      next:(res: TableauTrustedUrlResponseDto) => {
        if (res) {
          window.open(res.url, '_blank');
        }
      }
    });
  }

}
