import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "@/environments/environment";
import { Report, TableauTrustedUrlResponseDto } from "@/app/shared/interface/report.interface";

@Injectable({
  providedIn: "root",
})
export class ReportService {
  
  constructor(
    private http: HttpClient
) { }

  getUserTableauServices(): Observable<Report[]> {
    return this.http.get<Report[]>(`${environment.baseURL}report/tableaureport/reports`)
  }

  getTrustedUrlSupplier(reportName: string): Observable<TableauTrustedUrlResponseDto> {
    return this.http.get<TableauTrustedUrlResponseDto>(`${environment.baseURL}report/tableaureport/getTrustedUrl/supplier/${reportName}`)
  }
}