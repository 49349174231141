import { Component, EventEmitter, Output, ViewChild } from '@angular/core';
import { Observable } from 'rxjs';
import { AccountUser } from '../../../shared/interface/account.interface';
import { AddressModalComponent } from '@/app/shared/widgets/address-modal/address-modal.component';
import { DeleteModalComponent } from '@/app/shared/widgets/modal/delete-modal/delete-modal.component';
import {
  DeliveryAddress,
  DeliveryAddressModel,
  User,
  UserAddress,
} from '../../../shared/interface/user.interface';
import { AddressService } from '@/app/shared/services/address.service';
import { Province } from '@/app/shared/interface/province.interface';
import { ProvinceService } from '@/app/shared/services/province.service';
import { ToastrService } from 'ngx-toastr';
import { ConfirmationModalComponent } from '@/app/shared/widgets/modal/confirmation-modal/confirmation-modal.component';
import { AccountService } from '@/app/shared/services/account.service';

@Component({
  selector: 'app-adresses',
  templateUrl: './adresses.component.html',
  styleUrls: ['./adresses.component.scss'],
})
export class AdressesComponent {
  public provinces: Province;
  public statusedit: Number;
  public addresses: DeliveryAddress[] = [];
  public childAddresses: DeliveryAddressModel[] = [];
  public childModel: DeliveryAddressModel = { userName: "", deliveryAddress: [] };

  addressEdit: any;

  @Output() dataEvent = new EventEmitter<string>();
  @ViewChild('addressModal') AddressModal: AddressModalComponent;
  @ViewChild('confirmModal') confirmModal: ConfirmationModalComponent;
  @ViewChild('childAddressModal') childAddressModal: AddressModalComponent;
  @ViewChild('confirmChildModal') confirmChildModal: ConfirmationModalComponent;

  ngOnInit(): void {
    this.getAddresses();
    this.getChildAddresses();
  }

  constructor(
    private AddressService: AddressService,
    private toastr: ToastrService,
    private accountService: AccountService,
  ) { }

  onEditAddress(address: any) {
    this.addressEdit = address;
    this.AddressModal.openModal();
  }
  onEditChildAddress(address: any, childAddresses: DeliveryAddressModel) {
    this.addressEdit = address;
    this.childModel = childAddresses;
    this.childAddressModal.openModal();
  }

  onDeleteAddress(address: any) {
    this.addressEdit = address;
    if (this.addresses.length > 1) {
      this.confirmModal.openModal();
    } else {
      this.toastr.error('Bạn cần ít nhất 1 địa chỉ giao hàng', '', {
        positionClass: 'toast-top-right',
        timeOut: 5000,
        enableHtml: true,
      });
    }
  }
  onDeleteChildAddress(address: DeliveryAddress, childAddresses: DeliveryAddressModel) {
    this.addressEdit = address;
    this.childModel = childAddresses;
    if (childAddresses.deliveryAddress.length > 1) {
      this.confirmChildModal.openModal();
    } else {
      this.toastr.error('Tài khoản con cần ít nhất 1 địa chỉ giao hàng', '', {
        positionClass: 'toast-top-right',
        timeOut: 5000,
        enableHtml: true,
      });
    }
  }
  addAddress() {
    this.addressEdit = null;
    this.AddressModal.openModal();
  }

  resetAddress() {
    this.addressEdit = null;
  }

  getAddresses() {
    this.AddressService.getUserAddress().subscribe((res) => {
      this.addresses = res.deliveryAddress;
      this.addresses.sort((a, b) => {
        if (a.status > b.status) {
          return 1;
        }
        if (a.status < b.status) {
          return -1;
        }
        return 0;
      });
    });
  }

  getChildAddresses() {
    this.accountService.getUserData.subscribe({
      next: (res: User) => {
        if (res && res.childrens && res.childrens.length) {
          this.AddressService.getChildUserAddress().subscribe({
            next: (res: DeliveryAddressModel[]) => {
              this.childAddresses = res;
            },
            error: (err: any) => {
              console.error(err);
            }
          })
        }
      },
      error: (err: any) => {
        console.error(err);
      }
    })
  }

  addAddressAfterCreate(event: DeliveryAddress) {
    this.getAddresses();
  }

  confirmDelete(value?: boolean) {
    if (
      value &&
      this.addresses.length > 1 &&
      this.addresses.filter((address) => address.status === 0).length > 0
    ) {
      if (this.addressEdit) {
        this.statusedit = this.addressEdit.status;
        this.addressEdit.status = 2;
        this.AddressService.updateUserAddress(
          JSON.stringify(this.addressEdit)
        ).subscribe((res) => {
          if (res) {
            this.toastr.success('Xóa địa chỉ thành công !!', '', {
              positionClass: 'toast-top-right',
              timeOut: 5000,
              enableHtml: true,
            });
            this.getAddresses();
          }
          else {
            this.addressEdit.status = this.statusedit;
            this.toastr.error('Xóa địa chỉ không thành công !!', '', {
              positionClass: 'toast-top-right',
              timeOut: 5000,
              enableHtml: true,
            });
          }
        });
      }
    }
    else if (value && this.addresses.length == 0 &&
      this.addresses.filter((address) => address.status === 0).length == 0) {
      this.toastr.error('Bạn cần ít nhất 1 địa chỉ giao hàng hoặc 1 địa chỉ mặc định', '', {
        positionClass: 'toast-top-right',
        timeOut: 5000,
        enableHtml: true,
      });
    }
    else {

    }
  }
  confirmChildAddressDelete(value?: boolean) {
    if (
      value &&
      this.childModel.deliveryAddress.length > 1 &&
      this.childModel.deliveryAddress.filter((address) => address.status === 0).length > 0
    ) {
      if (this.addressEdit) {
        this.statusedit = this.addressEdit.status;
        this.addressEdit.status = 2;
        this.AddressService.updateUserAddress(
          JSON.stringify(this.addressEdit)
        ).subscribe((res) => {
          if (res) {
            this.toastr.success('Xóa địa chỉ thành công !!', '', {
              positionClass: 'toast-top-right',
              timeOut: 5000,
              enableHtml: true,
            });
            this.getChildAddresses();
          }
          else {
            this.addressEdit.status = this.statusedit;
            this.toastr.error('Xóa địa chỉ không thành công !!', '', {
              positionClass: 'toast-top-right',
              timeOut: 5000,
              enableHtml: true,
            });
          }
        });
      }
    }
    else if (value && this.childModel.deliveryAddress.length == 0 &&
      this.childModel.deliveryAddress.filter((address) => address.status === 0).length == 0) {
      this.toastr.error('Bạn cần ít nhất 1 địa chỉ giao hàng hoặc 1 địa chỉ mặc định', '', {
        positionClass: 'toast-top-right',
        timeOut: 5000,
        enableHtml: true,
      });
    }
    else {

    }
  }
}
