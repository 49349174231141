<div class="p-4 bg-white rounded-3 mobile-pd">
    <div class="total-box mt-0" *ngIf="reports.length">
        <div class="wallet-table mt-0 p-0">
            <div class="table-responsive">
                <table>
                    <thead>
                        <tr>
                            <th *ngFor="let col of headerColumns" class="d-none d-xl-table-cell d-lg-table-cell fw-bold">
                                {{ col }}
                            </th>
                            <th  class="d-xl-none d-lg-none fw-bold">
                                {{ headerColumns[0] }}
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let report of reports; let i = index">
                            <!-- mobile -->
                            <td class="d-xl-none d-lg-none">
                                <a class="theme-color" style="cursor: pointer;" (click)="openReport(report)">
                                    {{report.reportName}}
                                </a>
                            </td>
                            <!-- desktop -->
                            <td class="d-none d-xl-table-cell d-lg-table-cell">
                                {{report.reportName}}
                            </td>
                            <td class="d-none d-xl-table-cell d-lg-table-cell">
                                <a class="theme-color" style="cursor: pointer;" (click)="openReport(report)">Xem
                                    chi tiết</a>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>