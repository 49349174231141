import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '@/app/shared/shared.module';
import { AccountRoutingModule } from './account-routing.module';
import { TranslateModule } from '@ngx-translate/core';

import { AccountComponent } from './account.component';
import { ProfileComponent } from './profile/profile.component';
import { OrdersComponent } from './orders/orders.component';
import { OrderDetailsComponent } from './orders/details/details.component';
import { WishListComponent } from './wish-list/wish-list.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { AdressesComponent } from './adresses/adresses.component';
import { BusinessProfileComponent } from './business-profile/business-profile.component';
import { VoucherComponent } from './voucher/voucher.component';
import { InviteComponent } from './invite/invite.component';
import { ReportComponent } from './report/report.component';

@NgModule({
  declarations: [
    AccountComponent,
    ProfileComponent,
    OrdersComponent,
    OrderDetailsComponent,
    ChangePasswordComponent,
    WishListComponent,
    AdressesComponent,
    BusinessProfileComponent,
    VoucherComponent,
    InviteComponent,
    ReportComponent
  ],
  imports: [
    CommonModule,
    AccountRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    TranslateModule,
  ]
})
export class AccountModule { }
